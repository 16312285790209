import { Form, useActionData, Link, useNavigation, useLoaderData } from '@remix-run/react';
import type { ActionFunction, LoaderFunction } from '@remix-run/node';
import { json, redirect } from '@remix-run/node';
import { useEffect, useState } from 'react';
import { checkSession, createUserSession } from '~/services/session.server';
import { login } from '~/services/user.server';
import { Button, Stack, Box, Typography, Snackbar, Alert, } from '@mui/material';
import { Email, Lock } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { paperStyles } from '~/common/commonStyles';
import LoginTextField from '~/components/shared/FormElements/LoginTextField';



export const action: ActionFunction = async ({ request }) => {
    const formData = await request.formData();
    const email = formData.get('email');
    const password = formData.get('password');
    const redirectTo = formData.get('redirectTo')!.toString();

    const data = { email: email!.toString(), password: password!.toString() };

    const response = await login(data);

    if (response.error) {
        return json(
            { errors: { messageSnackbar: response.error } },
            { status: 400 }
        );
    }

    return await createUserSession({
        request,
        response,
        redirectTo,
    });

};

export const loader: LoaderFunction = async ({ request }) => {
    const url = new URL(request.url);
    const params = new URLSearchParams(url.search);
    const redirectTo = params.get('redirectTo') || '/home';

    if (await checkSession(request)) {
        return redirect(`/home`)
    }

    return json({ redirectTo });
};

export default function LoginPage() {
    const { t } = useTranslation()
    const { redirectTo } = useLoaderData()
    const actionData = useActionData()


    const navigation = useNavigation();
    const isSubmitting = navigation.state === 'submitting' || navigation.state === 'loading';

    const [showSnackbar, setShowSnackbar] = useState(false);
    const [fieldsError, setFieldsError] = useState({
        email: '',
        password: '',
        messageSnackbar: ''
    })

    useEffect(() => {
        if (actionData?.errors) {
            setFieldsError(actionData.errors);
            if (actionData?.errors.messageSnackbar?.length) {
                setShowSnackbar(true);
            }
        }
    }, [actionData]);

    return (
        <>
            <Stack
                height="100vh"
                width="100%"
                direction="column"
                bgcolor="secondary.dark"
            >
                <Box
                    width="100%"
                    maxWidth="1366px"
                    margin="auto"
                    textAlign="center"
                >
                    <img
                        src="bretit-logo.png"
                        alt="Bretit company"
                        width="293"
                        height="auto"
                    />
                    <Box sx={paperStyles}>
                        <Form method='post'>
                            <input type="hidden" name="redirectTo" value={redirectTo} />
                            <Stack spacing={2.5} direction="column">
                                <LoginTextField
                                    name="email"
                                    label={t("shared.email")}
                                    type='text'
                                    value=''
                                    placeHolder={t("login.email_placeholder")!}
                                    icon={<Email color='primary' />}
                                    error={fieldsError.email}
                                />
                                <LoginTextField
                                    name="password"
                                    label={t("shared.password")}
                                    type='password'
                                    value=''
                                    placeHolder={t("login.password_placeholder")!}
                                    icon={<Lock color='primary' />}
                                    error={fieldsError.password}
                                />
                                <Link to='forgot' style={{ textDecoration: "none" }}>
                                    <Typography variant='body2' children={t("login.forgot_password")} color="primary.main" />
                                </Link>
                                <Button type='submit' variant='contained' color='primary' disabled={isSubmitting}>
                                    {isSubmitting ? `${t("login.logging_in")}...` : t("login.login")}
                                </Button>
                            </Stack>
                        </Form>
                        <Box py={2}>
                            <Link to='/signup' style={{ textDecoration: "none" }}>
                                <Typography variant='body2' children={`${t("login.no_account")} ${t("registre.register")}`} color="primary.main" />
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </Stack>
            <Snackbar
                open={showSnackbar}
                autoHideDuration={5000}
                onClose={() => setShowSnackbar(false)}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                message="entro"
            >
                <Alert
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    {fieldsError?.messageSnackbar}
                </Alert>
            </Snackbar>
        </>
    );
}